/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useRef } from "react";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
// Images

import client from "ApiClient";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { Grid } from "@mui/material";

function Basic() {
  const [errorSB, setErrorSB] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const closeErrorSB = () => setErrorSB(false);
  const brandPixapage = `https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/images/pixapageL.png`;

  const handleLogin = () => {
    const options = {
      method: "GET",
      url: `users/adminlogin`,
      params: {
        email: emailRef.current.lastChild.firstChild.value,
        password: passwordRef.current.lastChild.firstChild.value,
      },
    };
    client
      .request(options)
      .then((response) => {
        if (response === undefined) {
          setErrorSB(true);
          return;
        }
        if (response === "Unauthorized") {
          setErrorSB(true);
          return;
        } else if (response.toString().indexOf("http") !== -1) {
          window.location.href = response.replace("/#/", "/");
        } else {
          localStorage.setItem("AuthorizationToken", JSON.stringify(response.user_token));
          localStorage.setItem("userName", response.user_name);
          localStorage.setItem("account", JSON.stringify(response));
          navigate("dashboard"); // window.location.href = "/"; // navigate("/blogArticles");
          window.location.reload();
        }
      })
      .catch(() => {
        setErrorSB(true);
      });
  };

  const renderErrorSB = (
    <MDSnackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      color="error"
      icon="warning"
      title="Login failed"
      content="Your credentails are wrong"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <PageLayout background="white">
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          size={{ xs: 12 }}
          sx={{
            backgroundImage: `url(${brandPixapage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: { xs: "90%", sm: "80%", md: "50%", lg: "30%" },
            backgroundPosition: "center",
            width: "100%",
          }}
        />
        <Grid item size={{ xs: 12 }} sx={{ width: "100%" }} display="flex">
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="top"
            width="100%"
          >
            <MDBox
              component="form"
              role="form"
              sx={{
                width: { xs: "90%", sm: "80%", md: "50%", lg: "30%" },
              }}
            >
              <MDBox mb={2}>
                <MDInput type="email" label="Email" fullWidth ref={emailRef} />
              </MDBox>
              <MDBox mb={2}>
                {/* si se presiona enter llamar a handleLogin */}

                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  ref={passwordRef}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleLogin(); // Llamar a handleLogin cuando se presiona Enter
                    }
                  }}
                />
              </MDBox>

              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" onClick={handleLogin} color="primary" fullWidth>
                  sign in
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      {renderErrorSB}
    </PageLayout>
  );
}

export default Basic;
